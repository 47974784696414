import React, { useState, useEffect, createRef } from "react";
import Input from "../../../UI/input/Input";
import CKEditorFull from "../../../systemSettings/communication/CkEditorFull";
import Config from "../../../systemSettings/communication/config";
import Button from "../../../UI/button/Button";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify"
import { sendUpdateEmailApi, updateStatusApi, mailBody } from "./helpers";

const EmailModal = ({ applicant, setShowModal, grantCampaign, status, applicationItem, setStatus }) => {
  const [email, setEmail] = useState('')
  const [subject, setSubject] = useState('')
  const [bodyText, setBodyText] = useState(mailBody(grantCampaign.organization_info))
  const [bcc, setBcc] = useState(false)

  const textEditorRef = createRef()

  useEffect(() => {
    if (applicant && grantCampaign) {
      setEmail(applicant.email)
      setSubject(`Change in application status | ${grantCampaign.name}`)
    }
  }, [applicant, grantCampaign])

  const updateStatus = () => {
    updateStatusApi(applicationItem, status).then(_ => {
      setStatus(status)
      setShowModal(false)
      toast.success("Successfully updated application status")
    }).catch((err) => {
      toast.error("Could not update the status of this application")
    })
  }

  const sendEmail = () => {
    Promise.all([
      updateStatusApi(applicationItem, status),
      sendUpdateEmailApi(applicationItem, email, subject, bodyText, bcc)
    ])
      .then(_ => {
        setStatus(status)
        setShowModal(false)
        toast.success('Status update email will be sent shortly')
      })
      .catch(error => {
        toast.error("Could not update the status of this application")
      });
  };

  const handleChange = () => {
    if (grantCampaign.organization_info.email == 'no-reply@amsnetwork.ca') {
      return toast.error("Please use a verified email for BCC")
    }

    setBcc(prevState => !prevState)
  }

  return(
    <div className='mt-3'>
      <div className="mb-2 pb-2">
        <Input
          elementType={"input"}
          elementConfig={{ required: true, type: "email" }}
          value={email}
          label="To Address"
          changed={event => setEmail(event.target.value)}
        />
        <div className="mb-2 pb-1">
          <Input
            elementType="input"
            elementConfig={{
              name: "mailSubject",
              type: "text",
              required: true
            }}
            label="Subject"
            changed={e => setSubject(e.target.value)}
            value={subject}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mb-4 pb-2">
            <label className="mb-2">Body Text</label>
            <CKEditorFull
              ref={textEditorRef}
              data={bodyText}
              config={Config}
              onChange={e => setBodyText(e.editor.getData())}
            />
          </div>
        </div>
      </div>
      <Input
        elementType={"input"}
        elementConfig={{ name: "ccToOrganizationEmail", type: "checkbox", checked: bcc }}
        value={bcc}
        label={`BCC ${grantCampaign.organization_info.email}`}
        changed={handleChange}
      />
      <div className="row">
        <div className="d-flex flex-row text-center justify-content-between mx-auto w-60">
          <Button
            type="secondary"
            className="mr-3"
            clicked={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="mr-3"
            clicked={updateStatus}
          >
            Update Status Only
          </Button>
          <Button
            type="primary"
            className="mr-3"
            clicked={sendEmail}
          >
            Update Status & Send Email
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(withRouter(EmailModal))
