import React from 'react'

export default function ApplicationFormItem({form, applicationFormItem, grantApplication}) {
  const viewCompletedForm = `/grant_applications/${grantApplication?.id}/application_form/${applicationFormItem?.id}/show`
  const goToForm = (form) => window.location.href=`${form}`

  return (
    <tr>
      <td>{form.name}</td>
      <td>{applicationFormItem && applicationFormItem.updated_when || "N/A"}</td>
      <td>{applicationFormItem && applicationFormItem.status || "Not Started"}</td>
      <td>
        <span
          className={`mr-1 fas fa-eye ${applicationFormItem?.status === "Complete" ? "text-primary cursor" : "text-muted"}`}
          style={applicationFormItem?.status !== "Complete" ? { cursor: "not-allowed" } : {}}
          data-toggle="tooltip"
          data-placement="top"
          title={applicationFormItem?.status === "Complete" ? "View" : "Incomplete"}
          onClick={applicationFormItem?.status === "Complete" ? () => goToForm(viewCompletedForm) : undefined}
        />
      </td>
    </tr>
  )
}
